import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";

import OpensourceIndex from "../components/opensource/OpensourceIndex";

const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/open-source.png",
};

const canonicalUrl = "https://hasura.io/opensource/";

const AllFeatures = props => (
  <Layout location={props.location}>
    <Seo
      title="Hasura GraphQL Engine | Open-source"
      description="Hasura GraphQL Engine is an open-source product that connects to your databases & services and gives you a realtime GraphQL API, instantly."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <OpensourceIndex />
  </Layout>
);

export default AllFeatures;
