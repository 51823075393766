import React, { useState, useEffect, useRef, useMemo } from "react";

import { Image } from "../../globals/UIKit";
import { Icon } from "../../globals/icons";
import { removePaddBottom } from "../shared/CommonStyled";
import StyledVideoWrapper from "../shared/StyledVideoWrapper";
import SubNav from "../solutions/common/SubNav";
import TopBanner from "../solutions/common/TopBanner";
import Features from "../database/sqlServer/Features";
import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import GetStarted from "../solutions/common/GetStarted";

import search from "./images/search.svg";
import notification from "./images/notification.svg";
import lock from "./images/lock.svg";
import rest_api from "./images/rest_api.svg";
import data_models from "./images/data_models.svg";
import event_trigger from "./images/event-trigger.svg";
import database from "../cloud/images/database.svg";
import contributor from "./images/contributor.svg";
import analytics from "./images/analytics.svg";

import tags from "./images/tags.png";
import fetch from "./images/fetch-brands.png";
import subscription from "./images/subscription.png";
import roleBasedSchemas from "./images/role-based-schemas.png";

const topBannerState = {
  title: "Hasura Community Edition",
  desc: "Hasura GraphQL Engine is an opensource product that connects to your databases & services and gives you a realtime GraphQL API, instantly.",
  btnContent: "Try on Hasura Cloud",
  btnLink: "https://cloud.hasura.io/signup?pg=opensource&plcmt=body&cta=try-hasura&tech=default",
  opensourcePage: true,
  width75: true,
};

const states = [
  {
    table: {
      payment: (
        <td>
          <i>NULL</i>
        </td>
      ),
      dispatched: (
        <td>
          <i>NULL</i>
        </td>
      ),
    },
    ui: {
      paymentClass: "",
      dispatchedClass: "",
      payment: (
        <td>
          <Image
            src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1669273992/main-web/icons/spinner-solid_naoiwl.svg"
            alt="check-icon"
            minWidth="18px"
            width="18px"
          />
        </td>
      ),
      dispatched: (
        <td>
          <i>...</i>
        </td>
      ),
    },
  },
  {
    table: {
      payment: (
        <td className="warning">
          <i>true</i>
        </td>
      ),
      dispatched: (
        <td>
          <i>NULL</i>
        </td>
      ),
    },
    ui: {
      paymentClass: "",
      dispatchedClass: "",
      payment: (
        <td>
          <Image
            src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1669273992/main-web/icons/spinner-solid_naoiwl.svg"
            alt="check-icon"
            minWidth="18px"
            width="18px"
          />
        </td>
      ),
      dispatched: (
        <td>
          <i>...</i>
        </td>
      ),
    },
  },
  {
    table: {
      payment: (
        <td className="warning">
          <i>true</i>
        </td>
      ),
      dispatched: (
        <td>
          <i>NULL</i>
        </td>
      ),
    },
    ui: {
      paymentClass: "success",
      dispatchedClass: "",
      payment: (
        <td className="success">
          <Image
            src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1669273992/main-web/icons/check-solid_qtb46l.svg"
            alt="check-icon"
            minWidth="18px"
            width="18px"
          />
        </td>
      ),
      dispatched: (
        <td>
          <Image
            src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1669273992/main-web/icons/spinner-solid_naoiwl.svg"
            alt="check-icon"
            minWidth="18px"
            width="18px"
          />
        </td>
      ),
    },
  },
  {
    table: {
      payment: (
        <td className="warning">
          <i>true</i>
        </td>
      ),
      dispatched: (
        <td className="warning">
          <i>true</i>
        </td>
      ),
    },
    ui: {
      paymentClass: "success",
      dispatchedClass: "",
      payment: (
        <td className="success">
          <Image
            src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1669273992/main-web/icons/check-solid_qtb46l.svg"
            alt="check-icon"
            minWidth="18px"
            width="18px"
          />
        </td>
      ),
      dispatched: (
        <td>
          <Image
            src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1669273992/main-web/icons/spinner-solid_naoiwl.svg"
            alt="check-icon"
            minWidth="18px"
            width="18px"
          />
        </td>
      ),
    },
  },
  {
    table: {
      payment: (
        <td className="warning">
          <i>true</i>
        </td>
      ),
      dispatched: (
        <td className="warning">
          <i>true</i>
        </td>
      ),
    },
    ui: {
      paymentClass: "success",
      dispatchedClass: "success",
      payment: (
        <td className="success">
          <Image
            src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1669273992/main-web/icons/check-solid_qtb46l.svg"
            alt="check-icon"
            minWidth="18px"
            width="18px"
          />
        </td>
      ),
      dispatched: (
        <td className="success">
          <Image
            src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1669273992/main-web/icons/check-solid_qtb46l.svg"
            alt="check-icon"
            minWidth="18px"
            width="18px"
          />
        </td>
      ),
    },
  },
];

const PostgresData = () => {
  const [currentIndex, setCurrentIndex] = useState(1);
  // const [intervalId, setIntervalId] = useState(-1);
  useEffect(() => {
    if (currentIndex < states.length - 1) {
      const timer = setTimeout(() => {
        setCurrentIndex(c => c + 1);
      }, 1300);
      return () => {
        clearTimeout(timer);
      };
    } else {
      const timer = setTimeout(() => {
        setCurrentIndex(1);
      }, 1300);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [currentIndex]);
  return (
    <table className="table table-striped table-bordered">
      <tbody>
        <tr>
          <th className="text-center" colSpan="3">
            Orders (postgres table)
          </th>
        </tr>
        <tr>
          <td>
            <b>order_id</b>
          </td>
          <td>
            <b>payment</b>
          </td>
          <td>
            <b>dispatched</b>
          </td>
        </tr>
        <tr>
          <td>XX-57</td>
          {}
          {states[currentIndex].table.payment ? states[currentIndex].table.payment : null}
          {states[currentIndex].table.dispatched ? states[currentIndex].table.dispatched : null}
        </tr>
      </tbody>
    </table>
  );
};

const UiUpdated = () => {
  const [currentIndex, setCurrentIndex] = useState(1);
  // const [intervalId, setIntervalId] = useState(-1);
  useEffect(() => {
    if (currentIndex < states.length - 1) {
      const timer = setTimeout(() => {
        setCurrentIndex(c => c + 1);
      }, 1300);
      return () => {
        clearTimeout(timer);
      };
    } else {
      const timer = setTimeout(() => {
        setCurrentIndex(1);
      }, 1300);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [currentIndex]);
  return (
    <table className="table table-borderless removeMarBottom">
      <tbody>
        <tr>
          <th className="text-center" colSpan="3">
            Order XX-57 (mobile/web UI)
          </th>
        </tr>
        <tr className={states[currentIndex].ui.paymentClass}>
          <td className="tableWd">
            <Image
              src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1669273696/main-web/icons/credit-card-solid_lra0sg.svg"
              alt="truck-icon"
              minWidth="18px"
              width="18px"
            />
          </td>
          <td>Payment</td>
          {states[currentIndex].ui.payment ? states[currentIndex].ui.payment : null}
        </tr>
        <tr className={states[currentIndex].ui.dispatchedClass}>
          <td>
            <Image
              src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1669273696/main-web/icons/truck-solid_sadk8a.svg"
              alt="truck-icon"
              minWidth="18px"
              width="18px"
            />
          </td>
          <td>Delivery</td>
          {states[currentIndex].ui.dispatched ? states[currentIndex].ui.dispatched : null}
        </tr>
      </tbody>
    </table>
  );
};

const cachingListState = [
  {
    id: "powerful-querying",
    imgSrc: search,
    linkContent: "Powerful querying",
    title: "Powerful queries out-of-the-box",
    atributeas: "h2",
    desc: (
      <span>
        <h3 className="fontBold pb8">Queries</h3>
        <span className="pb8">
          Query across tables & views with powerful filtering, pagination and pattern search.
        </span>
        <br />
        <a
          className="flexCenter"
          href="https://hasura.io/docs/latest/graphql/core/databases/postgres/queries/index.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Read more <Icon className="rightIcon arrow sm" variant="chevron_right" color="sky_80" />
        </a>
        <br />

        <h3 className="fontBold pb8">Insert Mutations</h3>
        <span className="pb8">
          Insert single or multiple objects. Make upsert operations on conflict to simplify app
          logic.
        </span>
        <br />
        <a
          className="flexCenter"
          href="https://hasura.io/docs/latest/graphql/core/databases/postgres/mutations/insert.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Read more <Icon className="rightIcon arrow sm" variant="chevron_right" color="sky_80" />
        </a>
        <br />

        <h3 className="fontBold pb8">Update Mutations</h3>
        <span className="pb8">
          Update objects based on a condition. Update to Increment/multiply directly.
        </span>
        <br />
        <a
          className="flexCenter"
          href="https://hasura.io/docs/latest/graphql/core/databases/postgres/mutations/update.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Read more <Icon className="rightIcon arrow sm" variant="chevron_right" color="sky_80" />
        </a>
        <br />

        <h3 className="fontBold pb8">Delete Mutations</h3>
        <span className="pb8">Delete one object or in bulk based on a condition.</span>
        <br />
        <a
          className="flexCenter"
          href="https://hasura.io/docs/latest/graphql/core/databases/postgres/mutations/delete.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Read more <Icon className="rightIcon arrow sm" variant="chevron_right" color="sky_80" />
        </a>
      </span>
    ),
    singleColumn: true,
    panelMultipleListImg: [
      {
        img: tags,
        desc: "Remove old product tags and insert new tags",
      },
      {
        img: fetch,
        desc: "Fetch first five brands with top ten products for each",
      },
    ],
  },
  {
    id: "subscriptions-and-live-queries",
    imgSrc: notification,
    linkContent: "Subscriptions and live queries",
    title: "Realtime with subscriptions and live queries",
    atributeas: "h2",
    desc: (
      <span>
        <div className="pb16">
          Convert any GraphQL query to a live query by using subscriptions.
        </div>
        <div className="pb16">
          <h3 className="fontBold pb16">1- Set up a GraphQL subscription</h3>
          <img src={subscription} alt="Subscription" />
        </div>
        <div className="pb16">
          <h3 className="fontBold pb16">2- As Postgres data changes...</h3>
          <PostgresData />
        </div>
        <div className="pb16">
          <h3 className="fontBold pb16">3- The UI is updated</h3>
          <UiUpdated />
        </div>
      </span>
    ),
  },
  {
    id: "dynamic-access-control",
    imgSrc: lock,
    linkContent: "Dynamic access control",
    title: "Dynamic access control that integrates with your auth",
    atributeas: "h2",
    desc: (
      <span>
        <ul className="discUl pb8">
          <li className="disc">Create field-level allow & deny rules with dynamic variables.</li>
          <li className="disc">Create role-based schemas.</li>
          <li className="disc">Integrate with an existing auth system.</li>
        </ul>
        <div>
          <img src={roleBasedSchemas} alt="dynamicAccess" />
        </div>
        <br />
        <br />
        <div className="pb8">
          <h3 className="fontBold pb16">To-do app with Auth0</h3>
          <div className="pb16">
            <a
              className="flexCenter"
              href="https://github.com/hasura/sample-apps/tree/main/todo-auth0-jwt"
              target="_blank"
              rel="noopener noreferrer"
            >
              Follow on Github
              <Icon className="rightIcon arrow sm" variant="chevron_right" color="sky_80" />
            </a>
          </div>
          <StyledVideoWrapper>
            <div className="videoAspectRatio">
              <iframe
                loading="lazy"
                title="Todo app with Auth0"
                src="https://www.youtube.com/embed/15ITBYnccgc?showinfo=0&rel=0"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
          </StyledVideoWrapper>
          <br />
          <br />
        </div>
        <div className="pb8">
          <h3 className="fontBold pb16">GraphQL using Gitlab’s auth</h3>
          <div className="pb16">
            <a
              className="flexCenter"
              href="https://github.com/hasura/gitlab-graphql"
              target="_blank"
              rel="noopener noreferrer"
            >
              Follow on Github
              <Icon className="rightIcon arrow sm" variant="chevron_right" color="sky_80" />
            </a>
          </div>
          <StyledVideoWrapper>
            <div className="videoAspectRatio">
              <iframe
                loading="lazy"
                title="GraphQL using GitLab’s Auth"
                src="https://www.youtube.com/embed/m1ChRhRLq7o?showinfo=0&rel=0"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
          </StyledVideoWrapper>
          <br />
          <br />
        </div>
        <div className="pb8">
          <h3 className="fontBold pb8">
            Restrict access in production to only pre-approved queries
          </h3>
          <div className="pb8">
            <div className="pb8">
              Create a list of safe queries in an allow-list and configure Hasura to restrict access
              to only the queries in this list.
            </div>
            <div className="pb16">
              <a
                className="flexCenter"
                href="https://hasura.io/docs/latest/graphql/core/deployment/allow-list.html#allow-list-for-queries"
                target="_blank"
                rel="noopener noreferrer"
              >
                Read more
                <Icon className="rightIcon arrow sm" variant="chevron_right" color="sky_80" />
              </a>
            </div>
          </div>
          <StyledVideoWrapper>
            <div className="videoAspectRatio">
              <iframe
                loading="lazy"
                title="Restrict access in production to only pre-approved queries"
                src="https://www.youtube.com/embed/gCsyApOTchw?showinfo=0&rel=0"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
          </StyledVideoWrapper>
        </div>
      </span>
    ),
  },
  {
    id: "write-business-logic-backed-by-rest-apis",
    imgSrc: rest_api,
    linkContent: "Write business logic backed by REST APIs",
    title: "Write business logic backed by REST APIs",
    atributeas: "h2",
    desc: (
      <span>
        <ul className="discUl pb8">
          <li className="disc">Define custom queries and mutations.</li>
          <li className="disc">Back it with business logic in REST APIs.</li>
          <li className="disc">
            Connect the REST API output with rest of the graph without writing any code.
          </li>
          <li className="disc">Use with Hasura permission system.</li>
        </ul>
        <div className="pb16">
          <a
            className="flexCenter"
            href="https://hasura.io/docs/latest/graphql/core/actions/index.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read more
            <Icon className="rightIcon arrow sm" variant="chevron_right" color="sky_80" />
          </a>
        </div>
        <StyledVideoWrapper>
          <div className="videoAspectRatio">
            <iframe
              loading="lazy"
              title="Write business logic backed by REST APIs"
              src="https://www.youtube.com/embed/oqbxEp4FIjE?showinfo=0&rel=0"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </StyledVideoWrapper>
      </span>
    ),
  },
  {
    id: "remote-schemas",
    imgSrc: data_models,
    linkContent: "Remote schemas",
    title: "Merge Remote GraphQL schemas",
    atributeas: "h2",
    desc: (
      <span>
        <ul className="discUl pb16">
          <li className="disc">
            Use remote schemas for custom business logic, delegating to HTTP APIs, etc.
          </li>
          <li className="disc">Seamlessly merge remote schemas behind a single GraphQL API.</li>
          <li className="disc">
            Supports GraphQL servers written in any language/framework, deployed anywhere.
          </li>
          <li className="disc">
            <a
              href="https://github.com/hasura/graphql-engine/tree/master/community/boilerplates/remote-schemas"
              target="_blank"
              rel="noopener noreferrer"
            >
              Boilerplates
            </a>{" "}
            available for popular languages and serverless platforms.
          </li>
        </ul>
        <StyledVideoWrapper>
          <div className="videoAspectRatio">
            <iframe
              loading="lazy"
              title="Merge Remote GraphQL schemas"
              src="https://www.youtube.com/embed/TpVTCxAm8Sc?showinfo=0&rel=0"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </StyledVideoWrapper>
      </span>
    ),
  },
  {
    id: "event-triggers-for-serverless",
    imgSrc: event_trigger,
    linkContent: "Event triggers for serverless",
    title: "Trigger webhooks on database events",
    atributeas: "h2",
    desc: (
      <span>
        <ul className="discUl pb16">
          <li className="disc">Invoke asynchronous business logic; Use with serverless.</li>
          <li className="disc">Build reactive UX with GraphQL subscriptions & live-queries.</li>
          <li className="disc">
            Powered by native Postgres triggers for reliable capture & delivery of database events.
          </li>
          <li className="disc">
            Available with live-demo apps, tutorials & boilerplates for serverless functions.
          </li>
        </ul>
        <StyledVideoWrapper>
          <div className="videoAspectRatio">
            <iframe
              loading="lazy"
              title="Trigger webhooks on database events"
              src="https://www.youtube.com/embed/EaTUVWnDCvA?showinfo=0&rel=0"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </StyledVideoWrapper>
      </span>
    ),
  },
  {
    id: "adding-to-an-existing-postgres-database",
    imgSrc: database,
    linkContent: "Adding to an existing postgres database",
    title: "Add to an existing, live postgres database",
    atributeas: "h2",
    desc: (
      <span>
        <div className="pb8">
          Add Hasura to any existing postgres database as-is and expose select tables & views over
          GraphQL.
          <br />
          <br />
          We took Gitlab CE (a complex rails + postgres application), hosted it on DigitalOcean and
          added GraphQL to it in just a few minutes. Check out the demo on the left!
        </div>
        <div className="pb8">
          <a
            className="flexCenter"
            href="https://github.com/hasura/gitlab-graphql"
            target="_blank"
            rel="noopener noreferrer"
          >
            View on Github
            <Icon className="rightIcon arrow sm" variant="chevron_right" color="sky_80" />
          </a>
        </div>
        <div className="pb16">
          <a
            className="flexCenter"
            href="https://hasura.io/docs/latest/graphql/core/getting-started/index.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            View docs to connect an existing database
            <Icon className="rightIcon arrow sm" variant="chevron_right" color="sky_80" />
          </a>
        </div>
        <StyledVideoWrapper>
          <div className="videoAspectRatio">
            <iframe
              loading="lazy"
              title="Add to an existing, live postgres database"
              src="https://www.youtube.com/embed/a2AhxKqd82Q?showinfo=0&rel=0"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </StyledVideoWrapper>
      </span>
    ),
  },
  {
    id: "admin-ui-and-schema-migrations",
    imgSrc: contributor,
    linkContent: "Admin UI and schema migrations",
    title: "Admin UI & Rails inspired schema migrations",
    atributeas: "h2",
    desc: (
      <span>
        <div className="pb16">
          As you use the UI to build and manipulate your GraphQL and database schema, Hasura
          automatically spits out migrations files that you can put in version control.
        </div>
        <StyledVideoWrapper>
          <div className="videoAspectRatio">
            <iframe
              loading="lazy"
              title="Admin UI & Rails inspired schema migrations"
              src="https://www.youtube.com/embed/cGjH_HjkWiU?showinfo=0&rel=0"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </StyledVideoWrapper>
      </span>
    ),
  },
  {
    id: "query-performance-analysis",
    imgSrc: analytics,
    linkContent: "Query performance analysis",
    title: "Query Performance Analysis",
    atributeas: "h2",
    desc: (
      <span>
        <ul className="discUl pb16">
          <li className="disc">Identify and fix performance bottlenecks in the console.</li>
          <li className="disc">Use Explain Analyze to add Db indexes.</li>
          <li className="disc">
            <a
              href="https://hasura.io/blog/tweaking-graphql-performance-using-postgres-explain-command-6a3d84fd9c9a/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Reducing latency on millions of rows from 20 secs → less than 1 sec.
            </a>
          </li>
        </ul>
        <StyledVideoWrapper>
          <div className="videoAspectRatio">
            <iframe
              loading="lazy"
              title="Query Performance Analysis"
              src="https://www.youtube.com/embed/a5J5eXHJZSk?showinfo=0&rel=0"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </StyledVideoWrapper>
      </span>
    ),
  },
];

const OpensourceIndex = () => {
  const featuresRef = useRef(null);
  const powerfulQueryingRef = useRef(null);
  const subscriptionsAndLiveQueriesRef = useRef(null);
  const dynamicAccessControlRef = useRef(null);
  const writeBusinessLogicBackedByRestApisRef = useRef(null);
  const remoteSchemasRef = useRef(null);
  const eventTriggersForServerlessRef = useRef(null);
  const addingToAnExistingPostgresDatabaseRef = useRef(null);
  const adminUiAndSchemaMigrationsRef = useRef(null);
  const queryPerformanceAnalysisRef = useRef(null);

  const sectionRefs = useMemo(
    () => [{ name: "Features", section: "features", ref: featuresRef }],
    []
  );

  const openSourceSection = {
    "powerful-querying": { section: "powerful-querying", ref: powerfulQueryingRef },
    "subscriptions-and-live-queries": {
      section: "subscriptions-and-live-queries",
      ref: subscriptionsAndLiveQueriesRef,
    },
    "dynamic-access-control": { section: "dynamic-access-control", ref: dynamicAccessControlRef },
    "write-business-logic-backed-by-rest-apis": {
      section: "write-business-logic-backed-by-rest-apis",
      ref: writeBusinessLogicBackedByRestApisRef,
    },
    "remote-schemas": { section: "remote-schemas", ref: remoteSchemasRef },
    "event-triggers-for-serverless": {
      section: "event-triggers-for-serverless",
      ref: eventTriggersForServerlessRef,
    },
    "adding-to-an-existing-postgres-database": {
      section: "adding-to-an-existing-postgres-database",
      ref: addingToAnExistingPostgresDatabaseRef,
    },
    "admin-ui-and-schema-migrations": {
      section: "admin-ui-and-schema-migrations",
      ref: adminUiAndSchemaMigrationsRef,
    },
    "query-performance-analysis": {
      section: "query-performance-analysis",
      ref: queryPerformanceAnalysisRef,
    },
  };

  return (
    <>
      <SubNav
        sectionRefs={sectionRefs}
        pageName="Hasura Community Edition"
        pagePath="/opensource/"
        currentDropDownMenu="products"
        subNavBtnLink="https://cloud.hasura.io/signup?pg=caching&plcmt=sub-header&cta=get-started-now&tech=default"
      />
      <TopBanner topBannerState={topBannerState} paddBottom />
      <div id="features" ref={featuresRef}>
        <Features title="Features" sections={openSourceSection} tabListState={cachingListState} />
      </div>
      <StyledSectionWrapper css={removePaddBottom}>
        <GetStarted
          title="Ready to get started with Hasura?"
          btnContent="Try on Hasura Cloud"
          btnLink="https://cloud.hasura.io/signup?pg=opensource&plcmt=body&cta=try-on-hasura-cloud&tech=default"
        />
      </StyledSectionWrapper>
    </>
  );
};

export default OpensourceIndex;
